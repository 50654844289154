import React from "react";
import Loadable from "react-loadable";

//import CodeEditors from './views/Editors/CodeEditors'
//import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>読み込み中...</div>;
}
/*
const Charts = Loadable({
  loader: () => import('./views/Charts'),
  loading: Loading,
});
*/
const Dashboard = Loadable({
  loader: () => import("./views/Pages/Dashboard"),
  loading: Loading,
});

const Home = Loadable({
  loader: () => import("./views/Pages/Home"),
  loading: Loading,
});

const Maintenance = Loadable({
  loader: () => import("./views/Pages/Maintenance"),
  loading: Loading,
});

const Login = Loadable({
  loader: () => import("./views/Pages/LoginMonitor"),
  loading: Loading,
});

//const LoginMonitor = Loadable({
//  loader: () => import("./views/Pages/LoginMonitor"),
//  loading: Loading,
//});

const PasswordReminder = Loadable({
  loader: () => import("./views/Pages/PasswordReminder"),
  loading: Loading,
});

const Sheet1 = Loadable({
  loader: () => import("./views/Pages/SheetOne"),
  loading: Loading,
});

const Sheet2 = Loadable({
  loader: () => import("./views/Pages/SheetTwo"),
  loading: Loading,
});

const Sheet3 = Loadable({
  loader: () => import("./views/Pages/SheetThree"),
  loading: Loading,
});

const SheetStudent = Loadable({
  loader: () => import("./views/Pages/SheetStudent"),
  loading: Loading,
});

const SchoolSettings = Loadable({
  loader: () => import("./views/Pages/SchoolSettings"),
  loading: Loading,
});

const AdminTop = Loadable({
  loader: () => import("./views/Pages/AdminTop"),
  loading: Loading,
});

const AdminExam = Loadable({
  loader: () => import("./views/Pages/AdminExam"),
  loading: Loading,
});

const InterviewSheet = Loadable({
  loader: () => import("./views/Pages/InterviewSheet"),
  loading: Loading,
});

const Tutorial = Loadable({
  loader: () => import("./views/Pages/Tutorial"),
  loading: Loading,
});

//webex
const WebexStudents = Loadable({
  loader: () => import("./views/Pages/Webex/Students/Students"),
  loading: Loading,
});

//student
const SheetStudent4Student = Loadable({
  loader: () => import("./views/Pages/SheetStudent4Student"),
  loading: Loading,
});
const StudentSettings = Loadable({
  loader: () => import("./views/Pages/StudentSettings"),
  loading: Loading,
});

const routes = [
  { path: "/", name: "Home", component: Home, exact: true },
  { path: "/maintenance", name: "Maintenance", component: Home, exact: true },

  { path: "/login", name: "Login", component: Login },
  { path: "/lgd", name: "Login", component: Login },
  //  { path: "/login_monitor", name: "LoginMonitor", component: LoginMonitor },
  {
    path: "/password_reminder/:token",
    name: "PasswordReminder",
    component: PasswordReminder,
  },

  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  {
    path: "/teacher/:nth/sheet1",
    name: "総合判定内訳",
    component: Sheet1,
  },
  {
    path: "/teacher/:nth/sheet2",
    name: "問題別正答率・選択率一覧",
    component: Sheet2,
  },
  {
    path: "/teacher/:nth/sheet3",
    name: "個人成績一覧",
    component: Sheet3,
  },
  {
    path: "/teacher/:nth/sheet_student/:sid",
    name: "個人別成績",
    component: SheetStudent,
  },
  {
    path: "/teacher/:nth/interview/:sid",
    name: "面談シート",
    component: InterviewSheet,
  },
  { path: "/teacher/settings", name: "設定", component: SchoolSettings },
  {
    path: "/teacher/:nth/webex/students",
    name: "Web受験者一覧",
    component: WebexStudents,
  },

  {
    path: "/student/:nth/sheet",
    name: "個人成績",
    component: SheetStudent4Student,
  },
  { path: "/student/settings", name: "設定", component: StudentSettings },
  { path: "/student/tutorial", name: "チュートリアル", component: Tutorial },

  /*{ path: "/admin/:id", name: "AdminTop", component: AdminTop },*/
  { path: "/admin/top", name: "AdminTop", component: AdminTop },
  { path: "/admin/exam/:nth", name: "AdminExam", component: AdminExam },

  //{ path: "/charts", name: "Charts", component: Charts },
];

export default routes;
