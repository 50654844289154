import React, { Component } from "react";
import { Nav } from "reactstrap";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import Cookies from "universal-cookie";
import GLOBALS from "../../Globals";
import {
  //  AppAsideToggler,
  AppNavbarBrand,
  AppSidebarToggler,
} from "@coreui/react";
//import DefaultHeaderDropdown from "./DefaultHeaderDropdown";
import nasumoshi_logo from "../../assets/img/brand/nasumoshi_logo.png";
import nasumoshi_logo_small from "../../assets/img/brand/nasumoshi_logo_small.png";

import logout_icon from "../../assets/img/logout.png";
import axios from "axios";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      name:
        localStorage.getItem("facility_name") !== "undefined"
          ? localStorage.getItem("facility_name")
          : localStorage.getItem("name"),
      code:
        localStorage.getItem("code") !== "null"
          ? localStorage.getItem("code")
          : localStorage.getItem("student_number"),
      entry: null,
    };
    this.handleSubmitLogout = this.handleSubmitLogout.bind(this);
    //const cookies = new Cookies();
  }

  handleSubmitLogout(event) {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios
      .delete(`${GLOBALS.API_SERVER_HOSTNAME}/faculty_users/sign_out`)
      .then((result) => {
        console.log("sign out..");
        //localStorage.setItem("id", null);
        const cookies = new Cookies();
        cookies.set("id", null, {
          path: "/",
          //maxAge: 2592000,
        });

        localStorage.setItem("name", null);
        //this.setState({ redirect: true });
        window.location.href = "/";
      })
      .catch(() => {
        console.log("ログアウトに失敗しました。");
        this.props.history.push("/login_im");
      });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to="/login" />;
    }

    //this.name = localStorage.getItem("facility_name");
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{
            src: nasumoshi_logo,
            width: 130,
            height: 40,
            alt: "Nasumoshi Logo",
          }}
          minimized={{
            src: nasumoshi_logo_small,
            width: 30,
            height: 30,
            alt: "Nasumoshi Logo",
          }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        {this.state.name ? (
          <span className="school_name">
            {this.state.code} {this.state.name}
          </span>
        ) : null}
        <Nav className="d-md-down-none" navbar>
          {/*
          <NavItem className="px-3">
            <NavLink href="/">Dashboard</NavLink>
          </NavItem>
          <NavItem className="px-3">Ð
            <NavLink href="#">Users</NavLink>
          </NavItem>
          <NavItem className="px-3">
            <NavLink href="#">Settings</NavLink>
          </NavItem>
        */}
        </Nav>
        <Nav className="ml-auto" entry={this.state.entry} navbar>
          {/*
          <DefaultHeaderDropdown notif/>
        */}
          <img
            src={"/images/logout.svg"}
            className="img-logout"
            alt="ログアウト"
            title="ログアウト"
            onClick={this.handleSubmitLogout}
          />
          {/*<DefaultHeaderDropdown accnt />*/}
        </Nav>
        {/*
        <AppAsideToggler className="d-md-down-none" />
        */}
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
