export default {
  MOSHI_ID: 5, // 1: なすぐらむ, 2:ほけもし, 3:さんもし, 4:かんもし, 5:なすもし
  YEAR: 2025,
  API_SERVER_HOSTNAME: "https://nasumoshi-api.intermed.co.jp", //production
  //API_SERVER_HOSTNAME: "https://nsmsd-api.intermed.co.jp", //demo
  //API_SERVER_HOSTNAME: "https://nsmsd-api.intermed.co.jp:3000", //test dev
  // API_SERVER_HOSTNAME: "http://localhost:3000", //local
  //API_SERVER_HOSTNAME: "http://nasumoshi-demo.intermed.co.jp",

  REQUIRED_THREAD: 80,

  //COLOSRS
  COLOR_REQUIRED_SUCCESS_MAIN: "#bded9a",
  COLOR_REQUIRED_FAILURE_MAIN: "#D9D9D9",
  COLOR_REQUIRED_SUCCESS_SUB: "#e5f8d7",
  COLOR_REQUIRED_FAILURE_SUB: "#F2F2F2",

  COLOR_NORMAL_SITUATION_A_MAIN: "#FFFFD9",
  COLOR_NORMAL_SITUATION_B_MAIN: "#FFE699",
  COLOR_NORMAL_SITUATION_C_MAIN: "#FFD9D9",
  COLOR_NORMAL_SITUATION_D_MAIN: "#FFB3B3",
  COLOR_NORMAL_SITUATION_E_MAIN: "#FF6565",
  COLOR_NORMAL_SITUATION_A_SUB: "#FFFFF7",
  COLOR_NORMAL_SITUATION_B_SUB: "#FFF8E1",
  COLOR_NORMAL_SITUATION_C_SUB: "#FFF7F7",
  COLOR_NORMAL_SITUATION_D_SUB: "#FFF3F3",
  COLOR_NORMAL_SITUATION_E_SUB: "#FFDDDD",

  COLOR_SUBJECT_REQUIRED_MAIN: "#FCCE7C",
  COLOR_SUBJECT_NORMAL_MAIN: "#B5D6FD",
  COLOR_SUBJECT_SITUATION_MAIN: "#FED2D3",
  COLOR_SUBJECT_NORMAL_SITUATION_MAIN: "#81D4E7",

  COLOR_SUBJECT_REQUIRED_SUB: "#FEF3DE",
  COLOR_SUBJECT_NORMAL_SUB: "#E8F2FE",
  COLOR_SUBJECT_SITUATION_SUB: "#FFEFEF",
  COLOR_SUBJECT_NORMAL_SITUATION_SUB: "#E6F6FA",
};
