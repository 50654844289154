import React from "react";
//import { NavLink } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import classNames from "classnames";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Cookies from "universal-cookie";
import axios from "axios";
import GLOBALS from "../../Globals";

import { AppSidebarNav } from "@coreui/react";

function _objectWithoutProperties(obj, keys) {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}
var _extends =
  Object.assign ||
  function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

const defaultProps = {};

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  navConfig: PropTypes.any,
};

class NasumoshiSidebarNav extends AppSidebarNav {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    this.state = {
      is_admin: cookies ? cookies.get("is_admin") === "true" : false,
      entry: null,
    };

    let tid = localStorage.getItem("facility_id"); //cookies ? cookies.get("facility_name") : null
    tid &&
      axios
        .get(`${GLOBALS.API_SERVER_HOSTNAME}/api/entries`, {
          withCredentials: true,
        })
        .then((result) => {
          this.setState({ entry: result.data });
        })
        .catch((e) => {
          console.log("通信に失敗しました。");
          if (!e.response) {
            alert("通信に失敗しました");
          } else if (e.response.status === 401) {
            this.props.history.push("/login");
          } else if (e.response.status === 404) {
            alert("学校が見つかりません");
          } else if (e.response.status === 500) {
            alert("エラーが発生しました");
          }
        });
  }

  render() {
    let regexp = /\/teacher\/(\d)\/sheet(\d+)/;
    let match = regexp.exec(this.props.location.pathname);
    let nth = match ? match[1] : null;

    if (!this.state.entry) {
      console.log("no entries");
      return <div></div>;
    }
    let items = [];
    if (this.state.is_admin) {
      items = [
        {
          title: true,
          name: "模試設定",
          class: "", // optional class names space delimited list for title item ex: "text-center"
        },
      ];
      this.state.entry &&
        this.state.entry.exams.map((exam, i) => {
          items.push({
            name: `第${exam.nth}回`,
            url: `/admin/exam/${exam.nth}`,
            icon: "fa",
          });
          return items;
        });
    } else {
      items = [
        {
          title: true,
          name: "教員用メニュー",
          wrapper: {
            element: "", // required valid HTML5 element tag
            attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
          },
          class: "", // optional class names space delimited list for title item ex: "text-center"
        },
      ];

      if (this.state.entry && this.state.entry.webexams.length > 0) {
        items.push({
          title: true,
          name: "Web受験",
          wrapper: {
            element: "", // required valid HTML5 element tag
            attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
          },
          class: "", // optional class names space delimited list for title item ex: "text-center"
        });
        for (let i = 0; i < this.state.entry.webexams.length; i++) {
          if (!this.state.entry.webexams[i] || !this.state.entry.webexams[i].id)
            continue;
          let exam = {
            name: `第${this.state.entry.webexams[i].nth}回 `,
            url: "/base",
            icon: "fa fa-chrome",
            class: `nth${i + 1} ${i + 1 == nth ? "active" : ""}`,
            children: [
              {
                name: "受験者一覧",
                popover: "Web受験対象となった受験生の一覧がご覧いただけます",
                url: `/teacher/${this.state.entry.webexams[i].nth}/webex/students`,
                icon: "fa fa-circle",
                label: {
                  variant: "students",
                },
              },
            ],
          };
          items.push(exam);
        }
      }

      if (this.state.entry && this.state.entry.exams.length > 0) {
        items.push({
          title: true,
          name: "採点結果",
          wrapper: {
            element: "", // required valid HTML5 element tag
            attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
          },
          class: "", // optional class names space delimited list for title item ex: "text-center"
        });
        for (let i = 0; i < this.state.entry.exams.length; i++) {
          if (!this.state.entry.exams[i] || !this.state.entry.exams[i].id)
            continue;
          let exam = {
            name: `第${this.state.entry.exams[i].nth}回 (${new Date(
              this.state.entry.exams[i].date
            ).toLocaleDateString("ja-JP")})`,
            url: "/base",
            icon: "fa fa-archive",
            class: `nth${i + 1} ${i + 1 == nth ? "active" : ""}`,
            children: [
              {
                title: true,
                name: `最新集計日: ${new Date(
                  this.state.entry.exams[i].calculated_at
                ).toLocaleDateString("ja-JP")}`,
                popover: "貴校全体の成績がご覧いただけます。",
                icon: "fa",
              },
              {
                name: "資料1 総合成績",
                popover: "貴校全体の成績がご覧いただけます。",
                url: `/teacher/${this.state.entry.exams[i].nth}/sheet1`,
                icon: "fa fa-circle",
                label: {
                  variant: "sheet1",
                },
              },
              {
                name: "資料2 問題別正答率・選択率一覧",
                popover:
                  "設問ごとに正答率の全国対比、選択率がご覧いただけます。",
                url: `/teacher/${this.state.entry.exams[i].nth}/sheet2`,
                icon: "fa fa-circle",
                label: {
                  variant: "sheet2",
                },
              },
              {
                name: "資料3 個人成績一覧",
                popover: "受験生別の成績がご覧いただけます",
                url: `/teacher/${this.state.entry.exams[i].nth}/sheet3`,
                icon: "fa fa-circle",
                label: {
                  variant: "sheet3",
                },
              },
            ],
          };
          items.push(exam);
        }
      }
    }
    var _props = this.props,
      className = _props.className,
      children = _props.children,
      //      navConfig = _props.navConfig,
      attributes = _objectWithoutProperties(_props, [
        "className",
        "children",
        "navConfig",
      ]);

    delete attributes.isOpen;
    delete attributes.staticContext;
    delete attributes.Tag;
    var navClasses = classNames(className, "sidebar-nav");
    var cancelMinHeight = {
      minHeight: "auto",
    };
    // sidebar-nav root
    return React.createElement(
      PerfectScrollbar,
      _extends({ className: navClasses }, attributes, {
        option: { suppressScrollX: true },
      }),

      <React.Fragment>
        <Nav style={cancelMinHeight}>{children}</Nav>

        {this.state.is_admin ? (
          <Nav style={cancelMinHeight}>{this.navList(items)}</Nav>
        ) : (
          <Nav style={cancelMinHeight}>
            {this.navList(items)}
            <NavItem className="nav-item">
              <NavLink className="nav-link" href="#/teacher/settings">
                <i className="nav-icon icon-settings"></i>設定
              </NavLink>
            </NavItem>
            <NavItem className="nav-title">サポート</NavItem>
            <NavItem className="nav-item">
              <NavLink
                className="nav-link"
                href="https://moshi-problem.s3-ap-northeast-1.amazonaws.com/nasumoshi/manual/nasumoshi_2022.pdf"
                target="_blank"
              >
                ご利用マニュアル
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="https://moshi-problem.s3-ap-northeast-1.amazonaws.com/nasumoshi/manual/csv_code_change.pdf"
                target="_blank"
              >
                CSVの文字化け対応マニュアル
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="https://www.intermed.co.jp/FS-APL/FS-Form/form.cgi?Code=im_contact"
                target="_blank"
              >
                お問い合わせ
              </NavLink>
            </NavItem>
          </Nav>
        )}
      </React.Fragment>
    );
  }
}

NasumoshiSidebarNav.propTypes = propTypes;
NasumoshiSidebarNav.defaultProps = defaultProps;

export default NasumoshiSidebarNav;
