import React from "react";
//import { NavLink } from "react-router-dom";
import { Nav, NavItem, NavLink } from "reactstrap";
import classNames from "classnames";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Cookies from "universal-cookie";
import axios from "axios";
import GLOBALS from "../../Globals";

import { AppSidebarNav } from "@coreui/react";

function _objectWithoutProperties(obj, keys) {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}
var _extends =
  Object.assign ||
  function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

const defaultProps = {};

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  navConfig: PropTypes.any,
};

class NasumoshiSidebarStudent extends AppSidebarNav {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    this.state = {
      is_admin: cookies ? cookies.get("is_admin") === "true" : false,
      entry: null,
    };

    let tid = localStorage.getItem("facility_name"); //cookies ? cookies.get("facility_name") : null

    tid &&
      axios
        .get(`${GLOBALS.API_SERVER_HOSTNAME}/api/entries`, {
          withCredentials: true,
        })
        .then((result) => {
          this.setState({ entry: result.data });
        })
        .catch((e) => {
          console.log("通信に失敗しました。");
          if (e.response.status === 401) {
            this.props.history.push("/login");
          } else if (e.response.status === 404) {
            alert("学校が見つかりません");
          } else if (e.response.status === 500) {
            alert("エラーが発生しました");
          }
        });
  }

  render() {
    //console.log(this.state)
    if (!this.state.entry) {
      console.log("no entries");
      return <div></div>;
    }
    let items = [];
    if (this.state.is_admin) {
      items = [
        {
          title: true,
          name: "模試設定",
          class: "", // optional class names space delimited list for title item ex: "text-center"
        },
      ];
      this.state.entry &&
        this.state.entry.exams.map((exam, i) => {
          items.push({
            name: `第${exam.nth}回`,
            url: `/admin/exam/${exam.nth}`,
            icon: "fa",
          });
          return items;
        });
    } else {
      items = [
        {
          title: true,
          name: "受験者用メニュー",
          wrapper: {
            element: "", // required valid HTML5 element tag
            attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
          },
          class: "", // optional class names space delimited list for title item ex: "text-center"
        },
      ];
      if (this.state.entry && this.state.entry.exams.length > 0)
        for (let i = 0; i < this.state.entry.exams.length; i++) {
          if (!this.state.entry.exams[i] || !this.state.entry.exams[i].id)
            continue;
          let exam = {
            name: `第${this.state.entry.exams[i].nth}回 (${new Date(
              this.state.entry.exams[i].date
            ).toLocaleDateString("ja-JP")})`,
            url: `/student/${this.state.entry.exams[i].nth}/sheet`, //"/base",
            icon: "fa fa-archive",
            // children: [
            //   {
            //     title: true,
            //     name: `最新集計日: ${new Date(
            //       this.state.entry.exams[i].calculated_at
            //     ).toLocaleDateString("ja-JP")}`,
            //     popover: "貴校全体の成績がご覧いただけます。",
            //     icon: "fa",
            //   },
            //   {
            //     name: "個人成績",
            //     popover: "受験生の成績がご覧いただけます",
            //     url: `/student/${this.state.entry.exams[i].nth}/sheet`,
            //     icon: "fa fa-circle",
            //     label: {
            //       variant: "sheet3",
            //     },
            //   },
            // ],
          };
          if (this.state.entry.exams[i].errata_url)
            exam.children.push({
              name: "正誤情報",
              popover: "出題の訂正について",
              url: this.state.entry.exams[i].errata_url,
              icon: "fa fa-exclamation",
              label: {
                variant: "errata",
              },
            });
          items.push(exam);
        }
    }
    var _props = this.props,
      className = _props.className,
      children = _props.children,
      //      navConfig = _props.navConfig,
      attributes = _objectWithoutProperties(_props, [
        "className",
        "children",
        "navConfig",
      ]);

    delete attributes.isOpen;
    delete attributes.staticContext;
    delete attributes.Tag;
    var navClasses = classNames(className, "sidebar-nav");
    var cancelMinHeight = {
      minHeight: "auto",
    };
    // sidebar-nav root
    return React.createElement(
      PerfectScrollbar,
      _extends({ className: navClasses }, attributes, {
        option: { suppressScrollX: true },
      }),

      <React.Fragment>
        <Nav style={cancelMinHeight}>{children}</Nav>

        {this.state.is_admin ? (
          <Nav style={cancelMinHeight}>{this.navList(items)}</Nav>
        ) : (
          <Nav style={cancelMinHeight}>
            {this.navList(items)}
            <NavItem className="nav-item">
              <NavLink className="nav-link" href="#/student/settings">
                <i className="nav-icon icon-settings"></i>設定
              </NavLink>
            </NavItem>
            <NavItem className="nav-title">サポート</NavItem>
            <NavItem className="nav-item">
              <NavLink className="nav-link" href="#/student/tutorial">
                <i className="nav-icon icon-layers"></i>
                Web成績表の使い方
              </NavLink>
            </NavItem>
          </Nav>
        )}
      </React.Fragment>
    );
  }
}

NasumoshiSidebarStudent.propTypes = propTypes;
NasumoshiSidebarStudent.defaultProps = defaultProps;

export default NasumoshiSidebarStudent;
