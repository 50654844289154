export default {
  items: [
    {
      title: true,
      name: "教員用メニュー",
      wrapper: {
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "teacher_nth", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "第1回",
      url: "/base",
      icon: "fa fa-archive",
      children: [
        {
          name: "資料1 総合成績",
          popover: "貴校全体の成績がご覧いただけます。",
          url: "/teacher/2021/3/sheet1",
          icon: "fa fa-circle",
          label: {
            variant: "sheet1",
          },
        },
        {
          name: "資料2 問題別正答率・選択率一覧",
          popover: "設問ごとに正答率の全国対比、選択率がご覧いただけます。",
          url: "/teacher/2021/3/sheet2",
          icon: "fa fa-circle",
          label: {
            variant: "sheet2",
          },
        },
        {
          name: "資料3 個人成績一覧",
          popover: "受験生別の成績がご覧いただけます",
          url: "/teacher/2021/3/sheet3",
          icon: "fa fa-circle",
          label: {
            variant: "sheet3",
          },
        },
      ],
    },
  ],
  admin_items: [
    {
      title: true,
      name: "管理機能",
      wrapper: {
        // optional wrapper object
        element: "", // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: "", // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: "管理者トップ",
      url: "/admin/",
      icon: "fa fa-circle",
      label: {
        variant: "admin_top",
      },
    },
  ],
};
