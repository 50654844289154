import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import { Redirect } from "react-router";
import axios from "axios";
import GLOBALS from "../../../Globals";

import Cookies from "universal-cookie";

class LoginMonitor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: localStorage.getItem("email"),
      student_number: localStorage.getItem("student_number"),
      password: null,
      student_password: null,
      redirect: false,
      password_reminder_modal_teacher: false,
      password_reminder_modal_student: false,
      password_reminder_email: null,
      password_reminder_email_student: null,
      notification: null,
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleSubmitTeacherLogin = this.handleSubmitTeacherLogin.bind(this);
    this.openPasswordReminderModal = this.openPasswordReminderModal.bind(this);
    this.togglePasswordReminderModalTeacher =
      this.togglePasswordReminderModalTeacher.bind(this);
    this.togglePasswordReminderModalStudent =
      this.togglePasswordReminderModalStudent.bind(this);
    this.passwordReminderFormChange =
      this.passwordReminderFormChange.bind(this);
    this.passwordReminderFormChangeStudent =
      this.passwordReminderFormChangeStudent.bind(this);

    this.handleSubmitPasswordReminderTeacher =
      this.handleSubmitPasswordReminderTeacher.bind(this);
    this.handleSubmitPasswordReminderStudent =
      this.handleSubmitPasswordReminderStudent.bind(this);

    this.handleChangeStudentNumber = this.handleChangeStudentNumber.bind(this);
    this.handleChangeStudentPassword =
      this.handleChangeStudentPassword.bind(this);
    this.handleSubmitStudentLogin = this.handleSubmitStudentLogin.bind(this);

    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios
      .delete(`${GLOBALS.API_SERVER_HOSTNAME}/faculty_users/sign_out`)
      .then((result) => {
        console.log("sign out..");
        //localStorage.setItem("id", null);
        const cookies = new Cookies();
        cookies.set("id", null, {
          path: "/",
          //maxAge: 2592000,
        });

        localStorage.setItem("name", null);
        //this.setState({ redirect: true });
      })
      .catch(() => {
        console.log("ログアウトに失敗しました。");
        this.props.history.push("/login_im");
      });
  }

  handleChangeStudentNumber(event) {
    this.setState({ student_number: event.target.value });
  }

  handleChangeStudentPassword(event) {
    this.setState({ student_password: event.target.value });
  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  handleSubmitTeacherLogin(event) {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios
      .post(`${GLOBALS.API_SERVER_HOSTNAME}/faculty_users/sign_in`, {
        faculty_user: {
          login_id: this.state.email,
          password: this.state.password,
        },
      })
      .then((result) => {
        if (result.data.success) {
          const cookies = new Cookies();
          cookies.set("faculty_user_id", result.data.id, {
            path: "/",
            //maxAge: 2592000,
          });
          cookies.set("is_admin", result.data.is_admin, {
            path: "/",
            //maxAge: 2592000,
          });

          localStorage.setItem("facility_name", result.data.facility_name);
          localStorage.setItem("facility_id", result.data.facility_id);
          localStorage.setItem("code", result.data.code);
          localStorage.setItem("name", result.data.facility_name);

          this.setState({ redirect: true });
        } else {
          alert("IDかパスワードが間違っています。");
        }
      })
      .catch(() => {
        alert("通信に失敗しました。");
      });
  }

  handleSubmitStudentLogin(event) {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios
      .post(`${GLOBALS.API_SERVER_HOSTNAME}/students/sign_in`, {
        student: {
          number: this.state.student_number,
          password: this.state.student_password,
        },
      })
      .then((result) => {
        if (result.data.success) {
          const cookies = new Cookies();
          let expire_date = new Date();
          expire_date.setFullYear(expire_date.getFullYear() + 1);
          cookies.set("student_id", result.data.id, {
            path: "/",
            //maxAge: 2592000,
          });

          localStorage.setItem("student_number", result.data.number);
          localStorage.setItem("name", result.data.name);
          localStorage.setItem("facility_id", result.data.facility_id);
          localStorage.setItem("facility_name", result.data.facility_name);
          localStorage.setItem("facility_code", result.data.facility_code);

          this.setState({ redirect: true });
        } else {
          alert("なすもしIDかパスワードが間違っています");
        }
      })
      .catch(() => {
        alert("通信に失敗しました。");
      });
  }

  togglePasswordReminderModalTeacher = () => {
    this.setState({
      password_reminder_modal_teacher:
        !this.state.password_reminder_modal_teacher,
    });
  };
  togglePasswordReminderModalStudent = () => {
    this.setState({
      password_reminder_modal_student:
        !this.state.password_reminder_modal_student,
    });
  };

  openPasswordReminderModal(event) {}

  passwordReminderFormChange(event) {
    this.setState({ password_reminder_email: event.target.value });
  }
  passwordReminderFormChangeStudent(event) {
    this.setState({ password_reminder_email_student: event.target.value });
  }

  handleSubmitPasswordReminderTeacher(event) {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios
      .post(`${GLOBALS.API_SERVER_HOSTNAME}/api/password_reminder`, {
        faculty_user: {
          email: this.state.password_reminder_email,
        },
      })
      .then((result) => {
        if (result.data.success) {
          this.setState({
            notification:
              "パスワード再発行を受け付けました。メールを確認してください",
          });
          this.togglePasswordReminderModalTeacher();
        } else {
          this.setState({
            notification: "エラー。時間をおいて再度送信してください",
          });
        }
      })
      .catch(() => {
        alert("通信に失敗しました。");
      });
  }

  handleSubmitPasswordReminderStudent(event) {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios
      .post(`${GLOBALS.API_SERVER_HOSTNAME}/api/student/password_reminder`, {
        student: {
          email: this.state.password_reminder_email_student,
        },
      })
      .then((result) => {
        if (result.data.success) {
          this.setState({
            notification:
              "パスワード再発行を受け付けました。メールを確認してください",
          });
          this.togglePasswordReminderModalStudent();
        } else {
          this.setState({
            notification: "エラー。時間をおいて再度送信してください",
          });
        }
      })
      .catch(() => {
        alert("通信に失敗しました。");
      });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to="/dashboard" />;
    }
    let is_demo_environment = window.location.host.includes("nsmsd");
    let is_local_environment = window.location.host.includes("localhost");

    return (
      <div
        className={`app flex-row align-items-center login ${
          is_local_environment
            ? "env_local"
            : is_demo_environment
            ? "env_demo"
            : null
        } `}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="12">
              {this.state.notification ? (
                <div className="alert alert-success fade show">
                  {this.state.notification}
                </div>
              ) : null}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="12">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Row>
                      <Col xs="12">
                        <h1>
                          なすもしWeb成績表&ensp;
                          <img
                            src="images/nasumoshi_logo.svg"
                            className="logo"
                            alt="logo"
                          ></img>
                        </h1>
                        <p className="text-muted">
                          <span className="small">
                            Web成績表へのログインは、ご実施されたマークシートが弊社に到着してから2～5営業日内に「Web成績表閲覧開始のご案内」のメールが到着した段階よりご覧いただけます。
                            <br />
                            ＊マークシート到着日および土日祝日は営業日に含みません。
                            <br />
                            ＊ログイン情報とは、なすもしIDおよびパスワードです。
                            <br />
                            <br />
                            マークシートの読み込みが完了し、web
                            成績表をご覧いただける状態になりましたらメールで
                            なすもしID、パスワードをお知らせいたします。
                          </span>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" sm="6" className="loginForm ">
                        <FormGroup row className="teacher">
                          <h2>教員ログイン</h2>
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-user"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="email"
                              placeholder="なすもしID"
                              name="email"
                              defaultValue={this.state.email}
                              onChange={this.handleChangeEmail}
                            />
                          </InputGroup>
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="password"
                              placeholder="Password"
                              name="password"
                              defaultValue={this.state.password}
                              onChange={this.handleChangePassword}
                            />
                          </InputGroup>
                          <InputGroup className="mb-3">
                            <Button
                              color="primary"
                              className="px-4"
                              onClick={this.handleSubmitTeacherLogin}
                            >
                              ログイン
                            </Button>{" "}
                          </InputGroup>
                          <p className="text-muted">
                            <br />
                            <a
                              href="https://moshi-problem.s3-ap-northeast-1.amazonaws.com/nasumoshi/manual/nasumoshi_2022.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <strong>
                                なすもしWeb成績表ご利用マニュアル(教員用)
                              </strong>
                            </a>
                            <br />
                            <br />
                            <span
                              onClick={this.togglePasswordReminderModalTeacher}
                              className="link finger"
                            >
                              <strong>パスワードを忘れた方はこちら</strong>
                            </span>
                          </p>
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6" className="loginForm ">
                        <FormGroup row className="student">
                          <h2>受験者ログイン</h2>
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-user"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="email"
                              placeholder="なすもしID"
                              name="email"
                              defaultValue={this.state.email}
                              onChange={this.handleChangeStudentNumber}
                            />
                          </InputGroup>
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="password"
                              placeholder="Password"
                              name="password"
                              defaultValue={this.state.password}
                              onChange={this.handleChangeStudentPassword}
                            />
                          </InputGroup>
                          <InputGroup className="mb-3">
                            <Button
                              color="primary"
                              className="px-4"
                              onClick={this.handleSubmitStudentLogin}
                            >
                              ログイン
                            </Button>
                          </InputGroup>
                          <p>
                            <br />
                            <a
                              href="https://nasumoshi.intermed.co.jp/#/student/tutorial"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <strong>
                                なすもしWeb成績表ご利用マニュアル(受験者用)
                              </strong>
                            </a>
                            <br />
                            <span
                              onClick={this.togglePasswordReminderModalStudent}
                              className="link finger"
                            >
                              <br />
                              <strong>パスワードを忘れた方はこちら</strong>
                            </span>
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6" className="watchan_standing_wrapper">
                        <img
                          src="images/watchan_standing.svg"
                          className="watchan_standing"
                          alt="watchan"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {/*
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.</p>
                      <Button color="primary" className="mt-3" active>Register Now!</Button>
                    </div>
                  </CardBody>
                </Card>
                */}
              </CardGroup>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={this.state.password_reminder_modal_teacher}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>パスワード再生成(教員)</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="12">
                <p>
                  ご登録のメールアドレスをご入力の上、「パスワード再設定メールを送る」ボタンを押してください。仮パスワードをメールでお送りいたします。
                </p>
                <Input
                  type="email"
                  placeholder="メールアドレス"
                  name="email"
                  defaultValue={this.state.password_reminder_email}
                  onChange={this.passwordReminderFormChange}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.handleSubmitPasswordReminderTeacher}
            >
              送信
            </Button>{" "}
            <Button
              color="secondary"
              onClick={this.togglePasswordReminderModalTeacher}
            >
              キャンセル
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.password_reminder_modal_student}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            パスワード再生成(受験者)
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="12">
                <p>
                  ご登録のメールアドレスをご入力の上、「パスワード再設定メールを送る」ボタンを押してください。仮パスワードをメールでお送りいたします。
                </p>
                <Input
                  type="email"
                  placeholder="メールアドレス"
                  name="email"
                  defaultValue={this.state.password_reminder_email}
                  onChange={this.passwordReminderFormChangeStudent}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.handleSubmitPasswordReminderStudent}
            >
              送信
            </Button>{" "}
            <Button
              color="secondary"
              onClick={this.togglePasswordReminderModalStudent}
            >
              キャンセル
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default LoginMonitor;
