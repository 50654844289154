import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { Redirect } from "react-router";

class Obj extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row>
        <Col xs="12">
          <h1>
            ほけもしWeb成績表&ensp;
            <img
              src="images/nasumoshi_logo.svg"
              className="logo"
              alt="logo"
            ></img>
          </h1>
          <p className="text-muted">
            <span className="small">
              Web成績表へのログインは、ご実施されたマークシートが弊社に到着してから2～5営業日内に「Web成績表閲覧開始のご案内」のメールが到着した段階よりご覧いただけます。
              <br />
              ＊マークシート到着日および土日祝日は営業日に含みません。
              <br />
              ＊ログイン情報とは、なすもしIDおよびパスワードです。
              <br />
              <br />
              ●ご担当先生へ
              <br />
              マークシートの読み込みが完了し、web成績表をご覧いただける状態になりましたらメールで
              なすもしID、パスワードをお知らせいたします。
              <br />
              ●受験生の方へ
              <br />
              成績の閲覧が可能になりましたらご担当の先生へお知らせいたします。
              <br />
              （マークシートが弊社に到着後2～5営業日でご覧いただけます）
            </span>
          </p>
        </Col>
      </Row>
    );
  }
}

export default Obj;
