import React, { Component } from "react";
import MoshiContext from "./context/moshiContext";

import { HashRouter, Route, Switch } from "react-router-dom";
import axios from "axios";

import "./App.css";
// Styles
// CoreUI Icons Set
import "@coreui/icons/css/coreui-icons.min.css";
// Import Flag Icons Set
import "flag-icon-css/css/flag-icon.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.css";

// Containers
import { DefaultLayout } from "./containers";
// Pages
import {
  Login,
  Page404,
  Page500,
  //  LoginMonitor,
  Maintenance,
} from "./views/Pages";
import PasswordReminder from "./views/Pages/PasswordReminder/PasswordReminder";

// import { renderRoutes } from 'react-router-config';
axios.defaults.xsrfCookieName = "CSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
axios.defaults.withCredentials = true;

//context

class App extends Component {
  render() {
    return (
      <MoshiContext.Provider value={{ moshi_id: 5 }}>
        <HashRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            {/*
          <Route
            exact
            path="/login_im"
            name="Login Page"
            component={LoginMonitor}
          />
    */}
            <Route
              exact
              path="/maintenance"
              name="Maintenance Page"
              component={Maintenance}
            />
            <Route
              exact
              path="/password_reminder"
              name="Login Page"
              component={PasswordReminder}
            />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </HashRouter>
      </MoshiContext.Provider>
    );
  }
}

export default App;
