import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
//import { Redirect } from "react-router";
import axios from "axios";
import GLOBALS from "../../../Globals";

class PasswordReminder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password1: null,
      password2: null,
    };
    this.handleChangePassword1 = this.handleChangePassword1.bind(this);
    this.handleChangePassword2 = this.handleChangePassword2.bind(this);
    this.handleSubmitPasswordReminder =
      this.handleSubmitPasswordReminder.bind(this);

    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Content-Type"] = "application/json";
  }

  handleChangePassword1(event) {
    this.setState({ password1: event.target.value });
  }
  handleChangePassword2(event) {
    this.setState({ password2: event.target.value });
  }

  handleSubmitPasswordReminder(event) {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios
      .post(`${GLOBALS.API_SERVER_HOSTNAME}/api/update_password`, {
        faculty_user: {
          password: this.state.password1,
          password_confirmation: this.state.password2,
          reset_password_token: this.props.match.params.token,
        },
      })
      .then((result) => {
        if (result.data.success) {
        } else {
          alert("メールアドレスかパスワードが間違っています。");
        }
      })
      .catch(() => {
        alert("通信に失敗しました。");
      });
  }

  passwordReminderFormChange(event) {
    this.setState({ password_reminder_email: event.target.value });
  }

  render() {
    if (this.state.redirect) {
      //  return <Redirect push to="/dashboard" />;
    }

    return (
      <div className="app flex-row align-items-center login">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              {this.state.notification ? (
                <div className="alert alert-success fade show">
                  {this.state.notification}
                </div>
              ) : null}
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Row>
                      <Col>
                        <h2>なすもし パスワード再発行</h2>
                        <p className="text-muted">
                          あたらしいパスワードを入力してください
                        </p>

                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="あたらしいパスワード"
                            name="password1"
                            defaultValue={this.state.password}
                            onChange={this.handleChangePassword1}
                          />
                        </InputGroup>
                        <InputGroup className="mb-4">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="icon-lock"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="あたらしいパスワード(確認)"
                            name="password2"
                            defaultValue={this.state.password}
                            onChange={this.handleChangePassword2}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6">
                        <Button
                          color="primary"
                          className="px-4"
                          onClick={this.handleSubmitPasswordReminder}
                        >
                          パスワード更新
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {/*
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.</p>
                      <Button color="primary" className="mt-3" active>Register Now!</Button>
                    </div>
                  </CardBody>
                </Card>
                */}
              </CardGroup>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={this.state.password_reminder_modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>パスワード再生成</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="12">
                <p>
                  ご登録のメールアドレスをご入力の上、「パスワード再設定メールを送る」ボタンを押してください。仮パスワードをメールでお送りいたします。
                </p>
                <Input
                  type="email"
                  placeholder="メールアドレス"
                  name="email"
                  defaultValue={this.state.password_reminder_email}
                  onChange={this.passwordReminderFormChange}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleSubmitPasswordReminder}>
              送信
            </Button>{" "}
            <Button
              color="secondary"
              onClick={this.togglePasswordReminderModal}
            >
              キャンセル
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default PasswordReminder;
