import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { Redirect } from "react-router";

import MoshiContext from "../../../../context/moshiContext";

import HeaderNasumoshi from "./Nasumoshi";
import HeaderHokemoshi from "./Hokemoshi";

class Obj extends Component {
  constructor(props) {
    super(props);
  }

  //	const components = [nil, nil,HeaderHokemoshi, nil ,nil, HeaderNasumoshi]
  render() {
    return (
      <MoshiContext.Consumer>
        {(moshi) => {
          switch (moshi.moshi_id) {
            case 2:
              return <HeaderHokemoshi />;
            case 5:
              return <HeaderNasumoshi />;
          }
        }}
      </MoshiContext.Consumer>
    );
  }
}

export default Obj;
