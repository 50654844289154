import React, { Component } from "react";
import {
  //  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  //  Input,
  //  InputGroup,
  //  InputGroupAddon,
  //  InputGroupText,
  Row,
  //  Modal,
  //  ModalHeader,
  //  ModalBody,
  //  ModalFooter,
  //  FormGroup,
} from "reactstrap";

//import GLOBALS from "../../../Globals";
//import Cookies from "universal-cookie";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let is_demo_environment = window.location.host.includes("nsmsd");
    let is_local_environment = window.location.host.includes("localhost");
    return (
      <div
        className={`app flex-row align-items-center maintenance ${
          is_local_environment
            ? "env_local"
            : is_demo_environment
            ? "env_demo"
            : null
        } `}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              {this.state.notification ? (
                <div className="alert alert-success fade show">
                  {this.state.notification}
                </div>
              ) : null}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Row>
                      <Col xs="12">
                        <h1>
                          なすもしWeb成績表&ensp;
                          <img
                            src="images/nasumoshi_logo.svg"
                            className="logo"
                            alt="logo"
                          ></img>
                        </h1>
                        <p className="text-muted">
                          <span className="small">
                            <strong>【メンテナンスのお知らせ】</strong>
                            <br />
                            　2021年8月28日（土）00:00〜31日（火）15:00まで
                            <br />
                            ※メンテナンス期間中はアクセスできない時間が発生したり、一時的に正しく表示されない場合がございますので、ご了承ください。
                            <br />
                            ※時間は前後する場合がございます。予めご了承ください。
                            <br />
                            ご迷惑をおかけいたしますが、ご理解いただきますようお願いいたします。
                          </span>
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
