import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { Redirect } from "react-router";

class Obj extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row className="loginPageNasumoshi">
        <Col xs="12">
          <h1>
            なすもしWeb成績表&ensp;
            <img
              src="images/nasumoshi_logo.svg"
              className="logo"
              alt="logo"
            ></img>
          </h1>

          {/* <div className="attention-news ">
            第113回看護師国家試験
            予想解答は令和6年2月12日（月）15:00に以下のページのInformationにて公開いたしました。
            <a href="https://www.intermed.co.jp/nasu/nasumoshi" target="_blank">
              https://www.intermed.co.jp/nasu/nasumoshi
            </a>
          </div> */}
          <p className="text-muted">
            <span className="small">
              {/* Web成績表へのログインは、ご実施されたマークシートが弊社に到着してから2～5営業日内に「Web成績表閲覧開始のご案内」のメールが到着した段階よりご覧いただけます。
              <br />
              ＊マークシート到着日および土日祝日は営業日に含みません。
              <br /> */}
              <b>
                <span className="blue">●</span>ご担当の先生へ
              </b>
              <br />
              マークシートの読み込みが完了し、web成績表をご覧いただける状態になりましたらメールでログイン情報をお知らせいたします。
              <br />
              ＊マークシートが弊社に到着後2〜5営業日でメールをお送りいたします。
              <br />
              ＊マークシート到着日および土日祝日は営業日に含みません。
              <br />
              ＊ログイン情報とは、なすもしIDおよびパスワードです。
              <br />
              <br />
              <b>
                <span className="yellow">●</span>受験者の方へ
              </b>
              <br />
              団体受験の方：成績の閲覧が可能になりましたらご担当の先生へお知らせいたします。
              <br />
              （マークシートが弊社に到着後2〜5営業日でご覧いただけます）
              <br />
              個人受験の方：マークシートが弊社に到着後2〜5営業日でご覧いただけます。
            </span>
          </p>
        </Col>
      </Row>
    );
  }
}

export default Obj;
