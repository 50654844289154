import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
//import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import Cookies from "universal-cookie";
import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
} from "@coreui/react";
// sidebar nav config
import navigation from "./_nav";
// routes config
import routes from "../../routes";

import DefaultAside from "./DefaultAside";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import NasumoshiSidebarTeacher from "./NasumoshiSidebarTeacher";
import NasumoshiSidebarStudent from "./NasumoshiSidebarStudent";
//import axios from "axios";
//import GLOBALS from "../../Globals";

class DefaultLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };
  }

  render() {
    const cookies = new Cookies();
    let tid = localStorage.getItem("facility_name"); //cookies.get("facility_name")

    let is_demo_environment = window.location.host.includes("nsmsd");
    let is_local_environment = window.location.host.includes("localhost");

    return (
      <div
        className={`app  ${
          is_local_environment
            ? "env_local"
            : is_demo_environment
            ? "env_demo"
            : null
        }`}
      >
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          {!tid ? null : (
            <AppSidebar fixed display="lg">
              <AppSidebarHeader />
              <AppSidebarForm />
              {parseInt(cookies.get("faculty_user_id")) ? (
                <NasumoshiSidebarTeacher navConfig={navigation} {...this.props}>
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      aria-current="page"
                      href="#/dashboard"
                    >
                      <i className="nav-icon icon-speedometer"></i>ホーム
                    </a>
                  </li>
                </NasumoshiSidebarTeacher>
              ) : (
                <NasumoshiSidebarStudent navConfig={navigation} {...this.props}>
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      aria-current="page"
                      href="#/dashboard"
                    >
                      <i className="nav-icon icon-speedometer"></i>ホーム
                    </a>
                  </li>
                </NasumoshiSidebarStudent>
              )}

              <AppSidebarFooter />
              <AppSidebarMinimizer />
            </AppSidebar>
          )}

          <main className="main">
            <AppBreadcrumb appRoutes={routes} className="breadcrumb_wrapper" />
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={(props) => <route.component {...props} />}
                    />
                  ) : null;
                })}
                {/*} <Redirect from="/" to="/dashboard" />*/}
              </Switch>
            </Container>
          </main>
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
